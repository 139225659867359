
import computedInputs from './reducers/inputsReducer'
import baseInputs from './reducers/baseInputsReducer'
import mainGame from './reducers/mainGameReducer'

import {combineReducers} from 'redux'

const appReducer = combineReducers({
    baseInputs,
    computedInputs,
    mainGame
})


const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
      return appReducer(undefined, action)
    }
  
    return appReducer(state, action)
}

export default rootReducer