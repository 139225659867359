import React from 'react';
import { useStopwatch } from 'react-timer-hook';

function StopWatch({active}) {


    const {
      seconds,
      minutes,
      hours,
      days,
    } = useStopwatch({ autoStart: active });
  
  const convertNumber = (n) => {
    return n > 9 ? "" + n: "0" + n;
  }

  return (
    <div style={{textAlign: 'center'}}>
      <div >
        <a>{convertNumber(hours)}</a>:<a>{convertNumber(minutes)}</a>:<a>{convertNumber(seconds)}</a>
      </div>
    </div>
  );
}


StopWatch.defaultProps = {
    active: false
}

export default StopWatch;
