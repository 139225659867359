const computedInputs = (state = {inputs: getInitInputs()}, action) => {
    switch(action.type){
        case "ADD_INPUT":
            for (var i = 0; i < state.inputs.length; i++) {
                if (state.inputs[i].value === 0) {
                    state.inputs[i].value = action.input.value;
                    state.inputs[i].used = false
                  break;
                }
              }
            return {...state, inputs: state.inputs}
            case "UPDATE_INPUT_USED":
                for (var i = 0; i < state.inputs.length; i++) {
                    if (state.inputs[i].id === action.input.id) {
                        state.inputs[i].used = true;
                      break;
                    }
                  }
                return {...state, inputs: state.inputs}
            case "UPDATE_INPUT_UNUSED":
                for (var i = 0; i < state.inputs.length; i++) {
                    if (state.inputs[i].id === action.input.id) {
                        state.inputs[i].used = false;
                      break;
                    }
                  }
                return {...state, inputs: state.inputs}
        default:
            return state
    }
}

const getInitInputs = () => {
    return [
    {id:1, value:0, type: 'computed', used: true},
    {id:2, value:0, type: 'computed', used: true},
    {id:3, value:0, type: 'computed', used: true},
    {id:4, value:0, type: 'computed', used: true},
    {id:5, value:0, type: 'computed', used: true},
    {id:6, value:0, type: 'computed', used: true}
]
}

export default computedInputs