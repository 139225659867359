import React from 'react';
import { Badge } from 'react-bootstrap';
import { DragDropContainer } from 'react-drag-drop-container';


const NumberCard = ({ input, background }) => {


    return (<DragDropContainer noDragging={input.used} dragData={input} targetKey={"computation"}  >
                <Badge style={{margin: "3px"}} bg={input.used ? "secondary" : background}>{input.value}</Badge>
            </DragDropContainer>  

    )
}

export default NumberCard