import './App.css';
import React, { useState } from 'react';
import MainGame from './components/MainGame'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal} from 'react-bootstrap'
import instructions from './Instructions.png'
import StopWatch from './components/StopWatch';



function App() {
  const [showInstructions, setShowInstructions] = useState(true)
  const [startGame, setStartGame] = useState(false)
  const handleClose = () => {
    if (startGame === false)
    {
      setStartGame(true)
    }
    setShowInstructions(false)
  };
  const handleShow = () => setShowInstructions(true);
  return (
    <div className="App">
      <header className="App-header">
      <div className="topnav">
        <a href="./" className="active">Home</a>
        <a style={{float: "right"}} onClick={handleShow}>About</a>
      </div>
      <div className='content'>
        <MainGame active={startGame}/>
        </div>
      </header>
      <Modal show={showInstructions} onHide={handleClose} className="instuctions">
      <Modal.Header closeButton>
          <Modal.Title >Instructions</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <img src={instructions} alt="instructions" />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default App;
