import React, { useState } from 'react';
import NumberCard from './NumberCard';

const Inputs = ({inputs, background}) => {
  return (
    <h2>
      {inputs.map((input, idx) => {
          return <NumberCard input={input} key={idx} style={{margin: "3px"}} background={background} />
      })}
    </h2>
  );
}

Inputs.defaultProps = {
    inputs: [0,0,0,0,0,0],
    background: "secondary"
}

export default Inputs