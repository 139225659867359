import React, { useState, useEffect } from 'react';
import { Badge, Offcanvas } from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux'
import { DropTarget } from 'react-drag-drop-container';
import AllActions from '../redux/actions';

const typeBgMapping = [
  {type:"default", bg:"secondary"},
  {type:"base", bg:"primary"},
  {type:"computed", bg:"info"},
]

const Computation = () => {

  const dispatch = useDispatch()

  const [first, setFirst] = useState({id:0, value:0, type:"default"});
  const [second, setSecond] = useState({id:0, value:0, type:"default"} );
  const [resultValidator, setResultValidator] = useState(0);
  const [result, setResult] = useState(0);
  const [operation, setOperation] = useState("+");
  const [operationStatus, setOperationStatus] = useState("warning");
  const [computationComplete, setComputationComplete] = useState(false);

  const total = useSelector(state => state.baseInputs.total)
  const handleOperation = () => {
    const operations = ["+","-", "/", "*"]
    const idx = operations.indexOf(operation)
    if (idx === 3)
    {
      setOperation(operations[0])
    }
    else{
      setOperation(operations[idx+1])
    }
  }

  const handleFirstDrop = (event) => {
    const newData = event.dragData
    const prevData = first
    setFirst(newData)
    if (newData.type === "base"){
      dispatch(AllActions.updateBaseInputAsUsed(newData))
    }
    else {
      dispatch(AllActions.updateInputAsUsed(newData))

    }
    if (prevData.type === "base"){
      dispatch(AllActions.updateBaseInputAsUnUsed(prevData))
    }
    else {
      dispatch(AllActions.updateInputAsUnUsed(prevData))

    }
  }

  const handleSecondDrop = (event) => {
    const newData = event.dragData
    const prevData = second
    setSecond(newData)
    if (newData.type === "base"){
      dispatch(AllActions.updateBaseInputAsUsed(newData))
    }
    else {
      dispatch(AllActions.updateInputAsUsed(newData))

    }
    if (prevData.type === "base"){
      dispatch(AllActions.updateBaseInputAsUnUsed(prevData))
    }
    else {
      dispatch(AllActions.updateInputAsUsed(prevData))

    }
  }

  const handleNumberOnClick = (number) =>
  {
    if (number === '⬅' && result >= 0 && result < 10)
    {
      setResult(0)
      return
    }
    if (number === '⬅' && result !== 0)
    {
      setResult(eval(result.toString().substring(0, result.toString().length - 1)))
      return
    }

    const resStr = result === 0 ? '' : result.toString()
    setResult(eval(resStr.concat(number.toString())))
  }

  useEffect(() => {
    const result = eval(first.value+operation+second.value)
    if (result %1 !== 0){
      setOperationStatus("danger")
      setResultValidator(0)
    }
    else{
      setOperationStatus("warning")
      setResultValidator(result)
    }
    
  }, [first, operation, second]);

  useEffect(() => {
    if (result === 0){
      return
    }
    if (result !== resultValidator){
      setComputationComplete(false)
    }
    else {
      setComputationComplete(true)
    }
  }, [result, resultValidator]);

  useEffect(() => {
    if (computationComplete){
      handleClose()
      
      if (result == total){
        console.log("success")
        dispatch(AllActions.gameSuccess())
        return
      }
      dispatch(AllActions.addInput({value:result}))
      dispatch(AllActions.incrementComputations())
    }
  }, [computationComplete]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);  

  const numbers = [1,2,3,4,5,6,7,8,9,0,'⬅']
  return (
    <div className='computation'>
    <h2>
       <DropTarget onHit={computationComplete ? () => {} : handleFirstDrop} targetKey= {"computation"}>
         <Badge key={"first"} style={{margin: "3px"}} bg={typeBgMapping.find(m => m.type === first.type).bg}>{first.value}</Badge>
       </DropTarget>
       <Badge onClick={computationComplete ? () => {} : handleOperation} key={"operation"} style={{margin: "3px"}} bg={operationStatus}>{operation}</Badge>
       <DropTarget onHit={computationComplete ? () => {} : handleSecondDrop} targetKey={"computation"}>
        <Badge key={"second"} style={{margin: "3px"}} bg={typeBgMapping.find(m => m.type === second.type).bg}>{second.value}</Badge>
       </DropTarget> 
       <Badge key={"equals"} style={{margin: "3px"}} bg={"warning"}>{"="}</Badge>
       <Badge key={"result"} style={{margin: "3px"}} bg={computationComplete ? "success" : "secondary"} onClick={ computationComplete ? () => {} : handleShow}>{result}</Badge>
    </h2>
      <Offcanvas scroll={true} style={{height:"18vh"}} placement={"bottom"} show={show} onHide={handleClose} >
        <Offcanvas.Body>
         <h2 style={{margin:"auto", textAlign:"center"}}>
           {numbers.map((number, idx) => {
              return <Badge onClick={() => handleNumberOnClick(number)}  style={{margin: "1px"}} bg={"secondary"} key={idx}>{number}</Badge>
           })}
         </h2>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default Computation