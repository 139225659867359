import game_data from '../../game_data.json';
import moment from 'moment'

const baseInputs = (state = {total:getInitTotal(), inputs: getInitInputs()}, action) => {
    
    switch(action.type){
        case "UPDATE_BASE_INPUT_USED":
            for (var i = 0; i < state.inputs.length; i++) {
                if (state.inputs[i].id === action.input.id) {
                    state.inputs[i].used = true;
                  break;
                }
              }
            return {...state, inputs: state.inputs}
        case "UPDATE_BASE_INPUT_UNUSED":
            for (var i = 0; i < state.inputs.length; i++) {
                if (state.inputs[i].id === action.input.id) {
                    state.inputs[i].used = false;
                  break;
                }
              }
            return {...state, inputs: state.inputs}
        default:
            return state
    }
}

const getInitInputs = () => {
  var today = moment().format("DD-MM-yyyy")
  const todays_game = game_data.find(d => {return d.date == today})
  return todays_game.numbers.split(',').map((i, idx) => {return {id:idx, value:i, type:'base', used: false}})

}

const getInitTotal = () => {
  var today = moment().format("DD-MM-yyyy")
  const todays_game = game_data.find(d => {return d.date == today})
  return todays_game.total

}

export default baseInputs 