const mainGame = (state = {computations: 1, gameSuccess: false, time: new Date()}, action) => {
    switch(action.type){
        case "INCREMENT_COMPUTATIONS":
            return {...state, computations: state.computations + 1}
        case "GAME_SUCCESS":
            const newTime = new Date() - state.time 
            return {...state, gameSuccess: true, time: newTime}
        case "SET_TOTAL_TIME":
            return {...state, time: action.time}
        default:
            return state
    }
}


export default mainGame