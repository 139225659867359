const reset = () =>
{
    return {
        type: "USER_LOGOUT"
    }
}

const gameSuccess = () =>
{
    return {
        type: "GAME_SUCCESS"
    }
}

const setTotalTime = (time) =>
{
    return {
        type: "SET_TOTAL_TIME",
        time: time
    }
}



const incrementComputations = () =>
{
    return {
        type: "INCREMENT_COMPUTATIONS"
    }
}


const addInput = (input) =>
{
    return {
        type: "ADD_INPUT",
        input: input
    }
}

const updateBaseInputAsUsed = (inputs) =>
{
    return {
        type: "UPDATE_BASE_INPUT_USED",
        input: inputs
    }
}

const updateBaseInputAsUnUsed = (inputs) =>
{
    return {
        type: "UPDATE_BASE_INPUT_UNUSED",
        input: inputs
    }
}

const updateInputAsUsed = (inputs) =>
{
    return {
        type: "UPDATE_INPUT_USED",
        input: inputs
    }
}

const updateInputAsUnUsed = (inputs) =>
{
    return {
        type: "UPDATE_INPUT_UNUSED",
        input: inputs
    }
}

const AllActions = {
    reset,
    gameSuccess,
    incrementComputations,
    addInput,
    updateInputAsUsed,
    updateInputAsUnUsed,
    updateBaseInputAsUsed,
    updateBaseInputAsUnUsed,
    setTotalTime
}

export default AllActions