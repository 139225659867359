import React, { useState } from 'react';
import { Badge, Modal, Button } from 'react-bootstrap';
import Computation from './Computation';
import Inputs from './Inputs';
import Total from './Total';
import AllActions from '../redux/actions'
import {useSelector, useDispatch} from 'react-redux'
import { RWebShare } from "react-web-share";
import StopWatch from './StopWatch';


function MainGame({active}) {
  const dispatch = useDispatch()

  const [key, setKey] = useState(1)

  const handleReset = () => {
    dispatch(AllActions.reset())
    setKey(Math.random())
  }
  var baseInputs = useSelector(state => state.baseInputs)
  var computedInputs = useSelector(state => state.computedInputs)
  var computations = useSelector(state => state.mainGame.computations)
  var gameSuccess = useSelector(state => state.mainGame.gameSuccess)
  var time = useSelector(state => state.mainGame.time)

  return (
    <div key={key}>
        <StopWatch key={active} active={active} />
        <Total value={baseInputs.total} />
        <hr size="8" width="100%"/>  
        <Inputs key={key+1} inputs={baseInputs.inputs} background="primary"/>
        <Inputs key={key} inputs={computedInputs.inputs} background="info"/>
        <hr size="8" width="100%"/>  
        <h1><Badge bg="dark" onClick={handleReset}>RESET</Badge></h1> 

        {[...Array(computations)].map((x, idx) =>
        {
          return <Computation key={idx} />
        }) 
        }
        <Modal className="success" show={gameSuccess} >
        <Modal.Header bsPrefix="modal-title text-center">
          <Modal.Title><div>Congratulations, you got it!</div>
          <div>You did it in: {new Date(time).toISOString().slice(11, 19)}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
        <img style={{height:"40%", width:"40%", paddingBottom: "50px", margin: "auto", display: "block"}} src="/success.png" alt="image" />
        
          <div>
      <RWebShare 
        data={{
          text: "I completed todays number game in " + new Date(time).toISOString().slice(11, 19),
          url: "https://algogames.co.uk/", 
          title: "SUCCESS!",
        }}
      >
        <Button className='center' variant="light">Share 🔗</Button>
      </RWebShare>
    </div></Modal.Body>
      </Modal>
    </div>
  );
}

StopWatch.MainGame = {
  active: false
}

export default MainGame