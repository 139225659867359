import React, { useState } from 'react';
import { Badge } from 'react-bootstrap';

const Total = ({value}) => {
  var digits = (""+value).split("");

  return (
    <h1>
      {digits.map((digit, idx) => {
          return <Badge key={idx} style={{margin: "3px"}} bg="primary">{digit}</Badge>
      })}
    </h1>
  );
}

Total.defaultProps = {
    value: "..."
}

export default Total